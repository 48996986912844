/* Section.css */
.jump-in-animation {
    transform: translateY(50px);
    opacity: 0;
  }
  
  .jump-in-animation.animate {
    animation: jumpIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
  
  @keyframes jumpIn {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  